// Dependencies
import React, { useState, useEffect, useContext } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
// Hooks
import useRandomItem from "../hooks/useRandomItem";
// SVGs
import Arrows from "../annotations/Arrows";
import Rings from "../annotations/Rings";
import Underlines from "../annotations/Underlines";
import Squiggles from "../annotations/Squiggles";

const Underline = ({ children }) => {

  const [color, palettes] = useContext(ColorContext);
  const randomColor = useRandomItem(palettes);

  return (
    <span className="a-underline">
      <span className="mark">
        <Underlines fill={color || randomColor.dark} text={children} />
      </span>
      {children}
    </span>
  )
}

const Ring = ({ children }) => {

  const [color, palettes] = useContext(ColorContext);
  const randomColor = useRandomItem(palettes);

  return (
    <span className="a-ring">
      <span className="mark">
        <Rings fill={color || randomColor.dark} text={children} />
      </span>
      {children}
    </span>
  )
}

const Arrow = ({ children }) => {

  const [color, palettes] = useContext(ColorContext);
  const randomColor = useRandomItem(palettes);

  return (
    <span className="a-arrow">
      <span className="mark">
        <Arrows fill={color || randomColor.dark} text={children} />
      </span>
      {children}
    </span>
  )
}

const Squiggle = ({ children }) => {

  const [color, palettes] = useContext(ColorContext);
  const randomColor = useRandomItem(palettes);

  return (
    <span className="a-squiggle">
      <span className="mark">
        <Squiggles fill={color || randomColor.dark} text={children} />
      </span>
      {children}
    </span>
  )
}

const options = {
  renderMark: {
    [MARKS.UNDERLINE]: text => <Underline>{text}</Underline>,
    [MARKS.BOLD]: text => <Ring>{text}</Ring>,
    [MARKS.CODE]: text => <Arrow>{text}</Arrow>,
    [MARKS.ITALIC]: text => <Squiggle>{text}</Squiggle>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
  },
  // renderText: text => {
  //   return text.split('\n').reduce((children, textSegment, index) => {
  //     return [...children, index > 0 && <br key={index} />, textSegment];
  //   }, []);
  // }
};

function usePalette() {
  const data = useStaticQuery(graphql`
    {
      allContentfulPalette {
        nodes {
          primary
          light
          lightest
          dark
          darkest
        }
      }
    }
  `)
  return data.allContentfulPalette.nodes
}


export const ColorContext = React.createContext();

const AnnotatedText = ({ className, field, richText, color }) => {

  const palettes = usePalette();

  return (
    <ColorContext.Provider value={[color, palettes]}>
      {richText ? (
        <div className={className}>
          {documentToReactComponents(richText.json, options)}
        </div>
      ) : (
        <div className={className} dangerouslySetInnerHTML={{__html: field.childMarkdownRemark.html}} />
      )}
    </ColorContext.Provider>
  )
}

export default AnnotatedText
